// Angular-Module
import {Component, Input, OnDestroy, OnInit} from '@angular/core';
// ReactiveX for JavaScript
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
// Globale Services
import {FileDownloadService} from '@global/services/file-download.service';
import {UserPermissionsService} from '@global/services/user-permissions.service';
/*
 * Service dieses Feature-Moduls
 * import {PeopleService} from '../people.service';
 */
import {ContactsDocumentsService} from './contacts-documents.service';
// Shared Services
import {GridService} from '@shared/grid/grid.service';
// Interfaces für Structured Objects einbinden
import {CWEvent} from '@shared/cw-event';
// import {Person} from '@shared/person';

@Component({
    selector: 'phscw-contacts-documents',
    templateUrl: './contacts-documents.component.html',
    styleUrls: ['./contacts-documents.component.scss'],
})
export class ContactsDocumentsComponent implements OnInit, OnDestroy {
    // Wird bei ngOnDestroy ausgelöst um Observables-Subscriptions zu stoppen
    private _componentDestroyed$ = new Subject<void>();

    // Flag definiert ob gerade geladen wird
    loading = false;
    // History-Daten
    data: any = [];
    // Flag, falls keine Daten vorhanden sind
    noData = false;

    // ID des Grids
    displayGridId = 'contactsDocumentsList';
    // Angezeigte Spalten in Grid zur Weitergabe an Shared-Documents-Component
    @Input() gridDisplayedColumns = [
        'document-date',
        'document-name',
        'document-description',
        'document-category',
        'edit-delete',
    ];

    // EditMode
    @Input() editMode = false;

    allowTicketEdit = false;

    // ID des aktuellen Kontakts - Über GETTER / SETTER, da bei Änderung ein Neuladen erfolgen soll
    _contactId = 0;
    get contactId() {
        return this._contactId;
    }

    @Input() set contactId(value) {
        // Wert übernehmen
        this._contactId = value;

        // Details laden (falls es sich NICHT um eine Neuanlage handelt - z.B. in Kontaktübersicht)
        /*
         * if (this.contactId >= 0) {
         *    this.loadDetails(this.contactId);
         * }
         */
    }

    /**
     * Konstruktor (inkl. dependency injection)
     * @param fileDownloadService
     * @param gridService
     * @param contactsDocumentsService
     * @param userPermissionsService
     */
    constructor(
        private fileDownloadService: FileDownloadService,
        private gridService: GridService,
        private contactsDocumentsService: ContactsDocumentsService,
        private userPermissionsService: UserPermissionsService,
    ) {}

    /**
     * Initialisieren
     */
    ngOnInit() {
        // Events subscriben
        this.initializeEventSubscriptions();
        this.allowTicketEdit = this.userPermissionsService.getPermissionValue('allowTicketEdit');
    }

    /**
     * Aufräumen
     */
    ngOnDestroy() {
        this._componentDestroyed$.next();
        this._componentDestroyed$.complete();
    }

    /**
     * @brief   EventSubscriptons
     * @author  Tobias Hannemann <t.hannemann@pharmakon.software>
     */
    initializeEventSubscriptions(): void {
        // Auf geänderte Auswahl reagieren
        /*
         * this.peopleService.selectionChanged.pipe(
         *    takeUntil(this._componentDestroyed$)
         * ).subscribe((result: number) => {
         *    // ID des Eintrags zwischenspeichern
         *    this.onSelectionChanged(result);
         * });
         */

        // Event "eventGridSelectionChanged" von "gridService"
        this.gridService.eventGridSelectionChanged
            .pipe(takeUntil(this._componentDestroyed$))
            .subscribe((result: any) => {
                // Event-Daten
                const event: CWEvent = result;
                // Abbruch, falls das Event nicht vom eigenen Grid (E-Liste) kam
                if (event.sender !== this.displayGridId) {
                    return;
                }
                this.onEventGridSelectionChanged(event);
            });
    }

    /**
     * @param event
     * @brief   Auf Klick einer Reihe im Grid reagieren
     * @author  Tobias Hannemann <t.hannemann@pharmakon.software>
     */
    onEventGridSelectionChanged(event: CWEvent): void {
        const documentId: number = event.data.selectedRow.id;
        const documentName: string = event.data.selectedRow.document_name;
        this.downloadFile(documentId, documentName);
    }

    /**
     * @param id
     * @param name
     * @brief   Dokument herunterladen
     * @todo    Fehleranzeige
     * @author  Tobias Hannemann <t.hannemann@pharmakon.software>
     */
    downloadFile(id: number, name: string): void {
        // Request auslösen
        const serviceRequest$ = this.contactsDocumentsService.getDocument(id);
        serviceRequest$.subscribe(
            (result: Blob) => {
                // Dialog zum Speichern öffnen
                this.fileDownloadService.openSaveDialog(result, name);
            },
            (error: any) => {
                // Error Handling
            },
        );
    }
}
