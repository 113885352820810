// Angular-Core
import {Component, Input, OnDestroy, OnInit} from '@angular/core';
// Service für Übersetzungen über NGX-Translate
import {TranslateService} from '@ngx-translate/core';
// ReactiveX for JavaScript
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
// Globale Services einbinden
import {BackendService} from '@global/services/backend.service';
import {UserPermissionsService} from '@global/services/user-permissions.service';
// Shared Services einbinden
import {DocumentsService} from './../documents.service';
// Services anderer Shared-Modules
import {ToolbarService} from '@shared/toolbar/toolbar.service';
// Interfaces für Structured Objects einbinden
import {hasOwn} from '@shared/utils';
import {CWEvent} from './../../cw-event';
import {CWResult} from './../../cw-result';
import {UploadFileData} from './../../document-data';
import {Notification} from '@shared/notification';
import * as _moment from 'moment';

const moment = _moment;

@Component({
    selector: 'phscw-documents-upload',
    templateUrl: './documents-upload.component.html',
    styleUrls: ['./documents-upload.component.scss'],
})
export class DocumentsUploadComponent implements OnInit, OnDestroy {
    // Wird bei ngOnDestroy ausgelöst um Observables-Subscription zu stoppen
    private _componentDestroyed$ = new Subject<void>();

    // Fehlermeldungen
    private _errorMessages = {
        fileExistsAlready: 'Die Datei existiert bereits.',
        uploadFailed: 'Die Datei konnte nicht hochgeladen werden.',
        sizeProhibited: 'Die Datei ist zu groß.',
        emptyFile: 'Die Datei ist leer(kein Inhalt).',
    };

    // Name des anzusprechenden Controllers
    @Input() backendController: string;
    @Input() type: string;
    // ID des aktuell ausgewählten Entrags
    private _entityId: number;
    @Input()
    set entityId(entityId: number) {
        // Dokumente der vorherigen Entität nicht anzeigen
        this.selectedFiles = [];
        this._entityId = entityId;
    }

    @Input() displayedColumns: string[] = ['document-name', 'document-description', 'document-category', 'delete'];

    // EditMode
    @Input() editMode = false;

    // Deaktiviert
    @Input() disabled = false;

    // Mehrere Dateien zur Auswahl zulassen
    @Input() allowMultiple = true;
    // Definiert die erlaubten Dateitypen für den Upload
    @Input() allowedMimeTypes: string =
        'application/vnd.ms-outlook, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document,' +
        'application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,' +
        'application/vnd.ms-powerpoint, application/vnd.openxmlformats-officedocument.presentationml.presentation,' +
        'application/pdf, application/zip, application/gzip, application/x-tar, application/x-gtar,' +
        'image/*, text/*';

    // Maximal erlaubte Dateigröße
    maxAllowedFileSize = 10000000;

    // Hover Hinweis
    hasFileHoldOver = false;
    // Duplikat Hinweis
    addedDuplicate = false;
    // Dateigröße Hinweis
    addedProhibitedSize = false;
    // Zum Upload ausgewählte Dateien
    selectedFiles: UploadFileData[] = [];
    formData: FormData = new FormData();

    // Flag definiert ob gerade gespeichert wird
    saving = false;
    dataNotification: Notification;

    /**
     * Konstruktor (inkl. dependency injection)
     * @param backendService
     * @param userPermissionsService
     * @param documentsService
     * @param toolbarService
     * @param translateService
     */
    constructor(
        private backendService: BackendService,
        private userPermissionsService: UserPermissionsService,
        private documentsService: DocumentsService,
        private toolbarService: ToolbarService,
        private translateService: TranslateService,
    ) {}

    /**
     * Initialisieren
     */
    ngOnInit() {
        // Übersetzungen subscriben
        this.initializeTranslateSubscriptions();

        // Berechtigung laden
        const maxAllowedFileSize: number = this.userPermissionsService.getPermissionValue('maximumUploadFileSize');
        this.maxAllowedFileSize = maxAllowedFileSize * 1000 * 1000;

        // Events subscriben
        this.initializeEventSubscriptions();
        this.dataNotification = new Notification();
    }

    /**
     * Aufräumen
     */
    ngOnDestroy() {
        this._componentDestroyed$.next();
        this._componentDestroyed$.complete();
    }

    /**
     * Events subscriben
     */
    initializeEventSubscriptions(): void {
        // Wenn ein neues Dokument für den Upload hinzugefügt werden soll
        this.toolbarService.eventAddItem.pipe(takeUntil(this._componentDestroyed$)).subscribe((result: CWEvent) => {
            const event: CWEvent = result;
            if (event.target.includes('documents') === false) {
                return;
            }
            this.onEventAddItem();
        });
    }

    /**
     * @brief   Übersetzungen subscriben
     * @details Subscribe auf Stream bekommt Änderung der Sprache mit
     *          und lädt Übersetzungen neu statt nur bei Initialisierung
     * @todo    Keys für stream() in Variable auslagern sobald von ngx-translate unterstützt wird
     * @author  Tobias Hannemann <t.hannemann@pharmakon.software>
     */
    initializeTranslateSubscriptions(): void {
        this.translateService
            .stream([
                'SHARED.DOCUMENTS.UPLOAD.DUPLICATEERROR',
                'SHARED.DOCUMENTS.UPLOAD.UPLOADERROR',
                'SHARED.DOCUMENTS.UPLOAD.SIZEERROR',
                'SHARED.DOCUMENTS.UPLOAD.CONTENTERROR',
            ])
            .pipe(takeUntil(this._componentDestroyed$))
            .subscribe((translation: string[]) => {
                this._errorMessages.fileExistsAlready = translation['SHARED.DOCUMENTS.UPLOAD.DUPLICATEERROR'];
                this._errorMessages.uploadFailed = translation['SHARED.DOCUMENTS.UPLOAD.UPLOADERROR'];
                this._errorMessages.sizeProhibited = translation['SHARED.DOCUMENTS.UPLOAD.SIZEERROR'];
                this._errorMessages.emptyFile = translation['SHARED.DOCUMENTS.UPLOAD.CONTENTERROR'];
            });
    }

    /**
     * Auf Event zum hinzufügen eines Dokuments reagieren
     */
    onEventAddItem(): void {
        // keine Dokumente zum Upload auswählen, wenn gerade ein Dokument bearbeitet wird
        if (this.disabled || this.editMode) {
            return;
        }

        // Input generieren
        const input: HTMLInputElement = document.createElement('input');

        // Attribute ergänzen und Input in Dokument einfügen
        input.setAttribute('style', 'display: none');
        input.type = 'file';
        input.multiple = this.allowMultiple;
        input.accept = this.allowedMimeTypes;
        document.body.appendChild(input);

        // Event-Listener für Change-Event setzen
        input.addEventListener(
            'change',
            ($event: any) => {
                const files: File[] = Array.from($event.target.files);
                this.setSelectedFiles(files);
            },
            false,
        );

        // Input klicken, um Dateiauswahl auszulösen, und anschließend Input entfernen
        input.click();
        input.remove();
    }

    /**
     * Zustand der gezogenen Datei zwischenspeichern
     */
    handleDragChange(): void {
        if (!this.disabled) {
            this.hasFileHoldOver = !this.hasFileHoldOver;
        }
    }

    /**
     * Auf Ziehen einer Datei reagieren
     * @param $event
     */
    handleFileDrag($event: any): void {
        $event.preventDefault();
    }

    /**
     * Auf Loslassen einer Datei reagieren
     * @param $event
     */
    handleFileDrop($event: any): void {
        // Hover-Notiz deaktivieren
        this.hasFileHoldOver = false;

        // Hinweis zurücksetzen
        this.addedDuplicate = false;
        this.addedProhibitedSize = false;

        // Datei nicht in Browser öffnen
        $event.preventDefault();

        if (!this.disabled && !this.editMode) {
            const files: File[] = Array.from($event.dataTransfer.files);
            this.setSelectedFiles(files);
        }
    }

    /**
     * Die ausgewählten Dateien prüfen und zwischenspeichern
     * @param files
     */
    setSelectedFiles(files: File[]): void {
        // Dateien in Liste zwischenspeichern
        files.forEach((selectedFile: File, index: number) => {
            // Auswahl mehrerer Dateien erlaubt?
            if (!this.allowMultiple && index >= 1) {
                return;
            }

            // Dateigröße prüfen
            if (!this.checkFileSizeBelowLimit(selectedFile)) {
                this.addedProhibitedSize = true;
                return;
            }

            // doppelte Auswahl verhindern und Hinweis anzeigen
            if (!this.findDuplicateSelection(selectedFile)) {
                this.selectedFiles.push({
                    fileObj: selectedFile,
                    description: '',
                    category: '',
                });
            } else {
                this.addedDuplicate = true;
            }
        });
    }

    /**
     * Prüfe Dateigröße der hochgeladenen Datei - gibt True zurück,
     * @param newFile
     */
    checkFileSizeBelowLimit(newFile: any): boolean {
        // Ist die Datei kleiner als das Limit?
        if (newFile.size > this.maxAllowedFileSize) {
            return false;
        }

        // Datei liegt unter Limit oder hatte keine Größe
        return true;
    }

    /**
     * Prüfen ob Dokumente bereits ausgewählt wurden für das Hochladen
     * @param newFile
     */
    findDuplicateSelection(newFile: any): boolean {
        // prüfe ob Datei bereits in Auswahl vorhanden ist -> Ergebnis wird in bool umgewandelt und zurückgegeben
        return !!this.selectedFiles.find((selection: any) => selection.fileObj.name === newFile.name);
    }

    /**
     * Datei aus der Auswahl entfernen
     * @param element
     */
    removeFileFromSelection(element: UploadFileData): void {
        this.selectedFiles = this.selectedFiles.filter((selection: any) => selection !== element);
    }

    /**
     * ausgewählte Dokumente hochladen
     */
    uploadSelectedFiles(): void {
        // Flag "saving" aktivieren
        this.saving = true;

        // ausgewählte Dateien und zusätzliche Eingaben zu FormData-Objekt hinzufügen
        this.selectedFiles.forEach((file: any) => {
            this.formData.append('files[]', file.fileObj);
            this.formData.append('descriptions[]', file.description);
            this.formData.append('categories[]', file.category);

            if (hasOwn(file, 'valid_from')) {
                this.formData.append('valid_from[]', file.valid_from.format('YYYY-MM-DDTHH:mm:ss.SSSZ'));
            }
            if (hasOwn(file, 'valid_to')) {
                this.formData.append('valid_to[]', file.valid_to.format('YYYY-MM-DDTHH:mm:ss.SSSZ'));
            }
        });
        // eslint-disable-next-line guard-for-in
        for (const key in this.dataNotification) {
            if (moment.isMoment(this.dataNotification[key])) {
                this.dataNotification[key] = this.dataNotification[key].format('YYYY-MM-DDTHH:mm:ss.SSSZ');
            }
            this.formData.append('Notifications[' + key + ']', this.dataNotification[key]);
        }

        // Request auslösen
        const serviceRequest$ = this.backendService.postRequest(
            'Documents/upload/' + this._entityId + '/' + this.type,
            this.formData,
        );
        serviceRequest$.subscribe((result: CWResult) => {
            /**
             * Prüfe, ob die Daten des eintreffenden Requests auch
             * zur aktuell ausgewählten Entität passen. Durch
             * asynchrone Abfragen kann es nämlich passieren, dass
             * zwischenzeitlich bereits die Entität gewechselt wurde
             * und die Antwort eines Requests verspätet eintrifft und
             * dadurch die korrekten Daten wieder überschreibt.
             */
            if (this._entityId != result.data['id']) {
                return;
            }

            // Ergebnis des Requests überprüfen
            this.onUploadSelectedFilesFinished(result);
        });

        // FormData zurücksetzen
        this.formData = new FormData();
    }

    /**
     * Ergebnis des Hochladens überprüfen
     * @param response
     */
    onUploadSelectedFilesFinished(response: CWResult): void {
        // Flag "saving" deaktivieren
        this.saving = false;

        // Duplikate markieren
        if (response.data['duplicates']) {
            response.data['duplicates'].forEach((duplicate: any) => {
                const untagged = this.selectedFiles.find((selection: any, index: number) => index === duplicate.uploadOrder);

                if (untagged) {
                    untagged.duplicate = this._errorMessages.fileExistsAlready;
                }
            });
        }

        // unerlaubte Dateien markieren
        if (response.data['sizeProhibited']) {
            response.data['sizeProhibited'].forEach((prohibited: any) => {
                const untagged = this.selectedFiles.find((selection: any, index: number) => index === prohibited.uploadOrder);
                untagged.sizeProhibited = this._errorMessages.sizeProhibited;
            });
        }

        // leere Dateien markieren
        if (response.data['emptyFile']) {
            response.data['emptyFile'].forEach((emptyFile: any) => {
                const untagged = this.selectedFiles.find((selection: any, index: number) => index === emptyFile.uploadOrder);
                untagged.emptyFile = this._errorMessages.emptyFile;
            });
        }

        // fehlgeschlagene Dateien markieren
        if (response.data['failedToMove']) {
            response.data['failedToMove'].forEach((failed: any) => {
                const untagged = this.selectedFiles.find((selection: any, index: number) => index === failed.uploadOrder);
                untagged.failedUpload = this._errorMessages.uploadFailed;
            });
        }

        // hochgeladene Dateien aus Auswahl entfernen
        if (response.success) {
            // Daten in der Antwort prüfen
            if (hasOwn(response.data, 'successful')) {
                // Daten wurden erfolgreich geändert --> Event über Service auslösen
                this.documentsService.dataChanged(this.backendController, response.data['successful']);

                response.data['successful'].forEach((file: any) => {
                    this.selectedFiles = this.selectedFiles.filter((selection) => selection.fileObj.name !== file.document_name);
                });
            } else {
                // Daten wurden erfolgreich geändert --> Event über Service auslösen
                this.documentsService.dataChanged(this.backendController, response['data']);

                // Daten zurücksetzen
                this.selectedFiles = [];
            }
        }
    }

    /**
     * Erzeugt die Benachritigungstext, potenziell ins Backend verschieben
     */
    generateDocumentNotification() {
        // const translatedType = this.translateService.instant('GENERAL.' + this.type.toUpperCase());
        return 'Dokument "' + this.selectedFiles[0].fileObj.name + '"';
    }
}
