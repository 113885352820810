// Angular-Module
import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
// ReactiveX for JavaScript
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
// Service für Übersetzungen über NGX-Translate
import {TranslateService} from '@ngx-translate/core';
// Service des übergeordneten Feature-Moduls
import {InstitutionsService} from '../institutions.service';
// Eigener Service
import {InstitutionsInvoicesService} from './institutions-invoices.service';
// Services von Shared Modules
import {GlobalRegionsfilterService} from '@global/components/global-regionsfilter/global-regionsfilter.service';
import {UserSettingsService} from '@global/services/user-settings.service';
import {GridService} from '@shared/grid/grid.service';
import {ToolbarService} from '@shared/toolbar/toolbar.service';
// Interfaces für Structured Objects einbinden
import {CWEvent} from '@shared/cw-event';
import {CWResult} from '@shared/cw-result';
import {FilterData} from '@shared/filter-data';
import {Invoice} from '@shared/invoice';
import {LooseObject} from '@shared/loose-object';
// Eigene Sammlung an Helfer-Methoden
import {hasOwn} from '@shared/utils';

// Globale Services einbinden
import {StorageService} from '@global/services/storage.service';
import {InitService} from '@global/services/init.service';

// Environment einbinden
import {environment} from '@environment';
// PopUp einbinden
import {PopupFormularComponent} from '@shared/popups/popup-formular/popup-formular.component';
// Interfaces einbinden
import {MOMENT_DB_FORMAT} from '@shared/input/input-date/input-date.component';
import * as moment from 'moment';
import {InstitutionsInvoicesFilterForm} from './institutions-invoices-filter-form';

@Component({
    selector: 'phscw-institutions-invoices',
    templateUrl: './institutions-invoices.component.html',
    styleUrls: ['./institutions-invoices.component.scss'],
})
export class InstitutionsInvoicesComponent implements OnInit, OnDestroy {
    // Wird bei ngOnDestroy ausgelöst um Observables-Subscriptions zu stoppen
    private _componentDestroyed$ = new Subject<void>();

    // ID der aktuell ausgewählten Einrichtung
    institutionId: number;

    // Filter mit Werten initialisieren
    private institutionsInvoicesFilterForm: InstitutionsInvoicesFilterForm = new InstitutionsInvoicesFilterForm();

    // Aktuell ausgewählter Regionsfilter
    regionsFilter: any;
    // Aktuell ausgewählter Rechnungsfilter
    invoiceFilter: LooseObject = {
        key: 'advanced',
        formular: {
            productGroup: '',
            startDate: moment().subtract(12, 'months').format(MOMENT_DB_FORMAT),
            endDate: moment().format(MOMENT_DB_FORMAT),
        },
    };

    // Modul-Daten (Rechnungen der Einrichtung - Gruppierte Listenansicht)
    dataInvoicesList: any[] = [];
    // Detail-Daten einer Rechnung (Rechnungspositionen)
    dataInvoicesPositions: any[] = [];
    // Gesamtsumme der Positionen in der Detailansicht
    dataInvoicesTotal = 0;
    // Currency-Symbol (wird in NgOnInit ggf. über Environment gesetzt / überschrieben)
    currency = '€';
    // Flag definiert, ob gerade geladen wird
    detailsLoading = false;
    // Liste mit Produktgruppen
    productGroupsSelect: any[] = [];

    // EditMode aktiv?
    @Input() editMode = false;

    // "Mehr laden..."
    loadMoreEnabled = false;
    loadMoreVisible = true;
    gridPage = 1;

    /**
     * *************************************************************************
     * RECHNUNGEN / LISTE
     *************************************************************************
     */
    // Spaltendefinitionen für Grid (Spezial-Spalten werden direkt im Grid definiert)
    gridColumns = [
        {
            columnDef: 'invoice-date',
            header: 'Datum',
            cell: (element: Invoice) => `${element.invoice_date}`,
            formatTemplate: 'date',
        },
        {
            columnDef: 'order-erp-number',
            header: 'Auftrag',
            cell: (element: Invoice) => `${element.order_erp_number}`,
            formatWidth: '90px',
        },
        {
            columnDef: 'employee-id',
            header: 'Mitarbeiter',
            cell: (element: Invoice) => `${element.employee_id}`,
            formatWidth: '125px',
        },
        {
            columnDef: 'employee',
            header: 'Mitarbeiter',
            cell: (element: Invoice) => {
                if (element.employee) {
                    if (element.employee.firstname) {
                        return `${element.employee.lastname || ''}, ${element.employee.firstname || ''} `;
                    }
                    return `${element.employee.lastname || ''}`;
                }
            },
            formatWidth: '225px',
        },
        {
            columnDef: 'region',
            header: 'Gebiet',
            cell: (element: Invoice) => {
                if (element.region.regionkey && element.region.name) {
                    return `${element.region.regionkey || ''} ${element.region.name || ''}`;
                } if (element.region.name) {
                    return `${element.region.name || ''}`;
                } if (element.region_id || element.region_id === 0) {
                    return `${element.region_id} (unbekannt)`;
                }
                return 'unbekannt';
            },
            formatWidth: '100px',
        },
        {
            columnDef: 'invoice-positions',
            header: 'Positionen',
            cell: (element: Invoice) => `${element.invoice_positions}`,
            formatWidth: '65px',
        },
        {
            columnDef: 'invoice-total',
            header: 'Betrag',
            cell: (element: Invoice) => `${element.invoice_total}`,
            formatTemplate: 'currency',
            formatWidth: '65px',
        },
    ];

    // Anzuzeigende Spalten für Grid
    gridDisplayedColumns = [
        'invoice-date',
        'order-erp-number',
        'region',
        'empty',
        'invoice-positions',
        'invoice-total',
    ];

    /**
     * *************************************************************************
     * RECHNUNGS-DETAILS / -POSITIONEN
     *************************************************************************
     */
    gridColumnsPositions = [
        {
            columnDef: 'invoice-position',
            header: 'Pos.',
            cell: (element: Invoice) => `${element.invoice_position || ''}`,
            formatWidth: '75px',
        },
        {
            columnDef: 'product-erp-number',
            header: 'Artikelnr.',
            cell: (element: Invoice) => `${element.product_erp_number || ''}`,
            formatWidth: '75px',
        },
        {
            columnDef: 'product-label',
            header: 'Artikel',
            cell: (element: Invoice) => `${element.product.label || 'Produkt nicht in Datenbank gefunden'}`,
        },
        {
            columnDef: 'position-discount',
            header: 'Rabatt',
            cell: (element: Invoice) => `${element.position_discount / 100 || ''}`,
            formatTemplate: 'percent',
            formatWidth: '65px',
        },
        {
            columnDef: 'position-unit-gross-price',
            header: 'Einzelpreis Brutto',
            cell: (element: Invoice) => `${element.position_gross_price / element.position_amount}`,
            formatTemplate: 'currency',
            formatWidth: '65px',
        },
        {
            columnDef: 'invoice-position-type',
            header: 'Positionsart',
            cell: (element: Invoice) => `${element.invoice_position_type || ''}`,
            formatWidth: '75px',
        },
        {
            columnDef: 'position-unit-price',
            header: 'Einzelpreis',
            cell: (element: Invoice) => `${element.position_price / element.position_amount}`,
            formatTemplate: 'currency',
            formatWidth: '65px',
        },
        {
            columnDef: 'position-amount',
            header: 'Absatz',
            cell: (element: Invoice) => `${element.position_amount || ''}`,
            formatTemplate: 'flush_right',
            formatWidth: '65px',
        },
        {
            columnDef: 'position-price',
            header: 'Umsatz',
            cell: (element: Invoice) => `${element.position_price || ''}`,
            formatTemplate: 'currency',
            formatWidth: '65px',
        },
        {
            columnDef: 'invoice-delivery-number',
            header: 'Lieferscheinnummer',
            cell: (element: Invoice) => `${element.delivery_number || ''}`,
            formatWidth: '75px',
        },
    ];

    gridDisplayedColumnsPositions = [
        'invoice-position',
        'product-erp-number',
        'product-label',
        'empty',
        'invoice-position-type',
        'position-unit-price',
        'position-amount',
        'position-price',
    ];

    // Filter für Rechnungen
    gridFilterFields: FilterData = {
        label: 'Erweiterter Filter',
        key: 'advanced',
        display: 'popup',
        popupTitle: 'Erweiterter Filter - Rechnungen',
        formular: {
            type: 'formular',
            data: this.institutionsInvoicesFilterForm.inputFields,
        },
    };

    // Konstruktor (inkl. dependency injection)
    constructor(
        private gridService: GridService,
        private toolbarService: ToolbarService,
        private institutionsService: InstitutionsService,
        private institutionsInvoicesService: InstitutionsInvoicesService,
        private globalRegionsfilterService: GlobalRegionsfilterService,
        private userSettingsService: UserSettingsService,
        private translateService: TranslateService,
        private filterDialog: MatDialog,
        private storageService: StorageService,
        private initService: InitService,
    ) {}

    // Initialisierungen
    ngOnInit() {
        // Events subscriben
        this.initializeEventSubscriptions();

        // Währung setzen
        if (typeof environment.defaultCurrency !== 'undefined') {
            this.currency = environment.defaultCurrency;
        }

        // Prüfe ob kundenspezifische Spalten für die Rechnungsübersicht definiert sind
        if (
            hasOwn(environment, 'institutionsInvoicesDisplayedColumnsCustomerSpecific') &&
            environment.institutionsInvoicesDisplayedColumnsCustomerSpecific.length > 0
        ) {
            this.gridDisplayedColumns = environment.institutionsInvoicesDisplayedColumnsCustomerSpecific;
        }

        // Prüfe ob kundenspezifische Spalten für die Rechnungsansicht definiert sind, andernfalls verwende den default
        if (
            hasOwn(environment, 'invoiceDetailsDisplayedColumnsCustomerSpecific') &&
            environment.invoiceDetailsDisplayedColumnsCustomerSpecific.length > 0
        ) {
            this.gridDisplayedColumnsPositions = environment.invoiceDetailsDisplayedColumnsCustomerSpecific;
        } else if (hasOwn(environment, 'invoiceDetailsDisplayedColumns')) {
            this.gridDisplayedColumnsPositions = environment.invoiceDetailsDisplayedColumns;
        }

        // regionsFilter mit den aktuellen Usersettings initialisieren
        this.regionsFilter = {
            division: this.userSettingsService.getValue('institutionsRegionsfilterDivision'),
            region: this.userSettingsService.getValue('institutionsRegionsfilterRegion'),
        };

        // Übersetzungen subscriben
        this.initializeTranslateSubscriptions();
    }

    // Aufräumen
    ngOnDestroy() {
        this._componentDestroyed$.next();
        this._componentDestroyed$.complete();
    }

    /**
     * @brief   Übersetzungen subscriben
     * @details Subscribe auf Stream bekommt Änderung der Sprache mit
     *          und lädt Übersetzungen neu statt nur bei Initialisierung
     * @todo    Keys für stream() in Variable auslagern sobald von ngx-translate unterstützt wird
     * @author  Tobias Hannemann <t.hannemann@pharmakon.software>
     * @author  Min Hye Park     <m.park@pharmakon.software>
     */
    initializeTranslateSubscriptions() {
        this.translateService
            .stream([
                'GENERAL.DATE',
                'GENERAL.ORDER',
                'GENERAL.REGION',
                'MODULES.SALES.DAILY.LIST.POSITIONS',
                'MODULES.SALES.DAILY.LIST.INVOICEAMOUNT',
                'MODULES.INSTITUTIONS.INVOICES.POSITION',
                'MODULES.INSTITUTIONS.INVOICES.POSITIONAMOUNT',
                'MODULES.INSTITUTIONS.INVOICES.PRODUCTERPNUMBER',
                'MODULES.INSTITUTIONS.INVOICES.PRODUCTLABEL',
                'MODULES.INSTITUTIONS.INVOICES.POSITIONTYPE',
                'MODULES.INSTITUTIONS.INVOICES.POSITIONUNITPRICE',
                'MODULES.INSTITUTIONS.INVOICES.POSITIONUNITPRICEGROSS',
                'MODULES.INSTITUTIONS.INVOICES.POSITIONPRICE',
                'MODULES.INSTITUTIONS.INVOICES.POSITIONDISCOUNT',
            ])
            .pipe(takeUntil(this._componentDestroyed$))
            .subscribe((translation: any) => {
                this.gridColumns.find((column: any) => column.columnDef === 'invoice-date').header =
                    translation['GENERAL.DATE'];
                this.gridColumns.find((column: any) => column.columnDef === 'order-erp-number').header =
                    translation['GENERAL.ORDER'];
                this.gridColumns.find((column: any) => column.columnDef === 'employee-id').header =
                    translation['GENERAL.EMPLOYEE'];
                this.gridColumns.find((column: any) => column.columnDef === 'region').header =
                    translation['GENERAL.REGION'];
                this.gridColumns.find((column: any) => column.columnDef === 'invoice-positions').header =
                    translation['MODULES.SALES.DAILY.LIST.POSITIONS'];
                this.gridColumns.find((column: any) => column.columnDef === 'invoice-total').header =
                    translation['MODULES.SALES.DAILY.LIST.INVOICEAMOUNT'];
                this.gridColumnsPositions.find((column: any) => column.columnDef === 'invoice-position').header =
                    translation['MODULES.INSTITUTIONS.INVOICES.POSITION'];
                this.gridColumnsPositions.find((column: any) => column.columnDef === 'product-erp-number').header =
                    translation['MODULES.INSTITUTIONS.INVOICES.PRODUCTERPNUMBER'];
                this.gridColumnsPositions.find((column: any) => column.columnDef === 'product-label').header =
                    translation['MODULES.INSTITUTIONS.INVOICES.PRODUCTLABEL'];
                this.gridColumnsPositions.find((column: any) => column.columnDef === 'invoice-position-type').header =
                    translation['MODULES.INSTITUTIONS.INVOICES.POSITIONTYPE'];
                this.gridColumnsPositions.find((column: any) => column.columnDef === 'position-unit-price').header =
                    translation['MODULES.INSTITUTIONS.INVOICES.POSITIONUNITPRICE'];
                this.gridColumnsPositions.find(
                    (column: any) => column.columnDef === 'position-unit-gross-price',
                ).header = translation['MODULES.INSTITUTIONS.INVOICES.POSITIONUNITPRICEGROSS'];
                this.gridColumnsPositions.find((column: any) => column.columnDef === 'position-price').header =
                    translation['MODULES.INSTITUTIONS.INVOICES.POSITIONPRICE'];
                this.gridColumnsPositions.find((column: any) => column.columnDef === 'position-amount').header =
                    translation['MODULES.INSTITUTIONS.INVOICES.POSITIONAMOUNT'];
                this.gridColumnsPositions.find((column: any) => column.columnDef === 'position-discount').header =
                    translation['MODULES.INSTITUTIONS.INVOICES.POSITIONDISCOUNT'];
            });
    }

    // Events subscriben
    initializeEventSubscriptions() {
        // In der E-Liste wird eine Einrichtung ausgewählt
        this.institutionsService.selectionChanged
            .pipe(takeUntil(this._componentDestroyed$))
            .subscribe((result: any) => {
                this.onSelectionChanged(result);
            });

        // In der Rechnungsliste wird eine Rechnung angeklickt
        this.gridService.eventGridSelectionChanged
            .pipe(takeUntil(this._componentDestroyed$))
            .subscribe((result: any) => {
                this.onEventGridSelectionChanged(result);
            });

        // Es wurde auf "Mehr laden..." geklickt
        this.gridService.eventGridPageCounterChanged
            .pipe(takeUntil(this._componentDestroyed$))
            .subscribe((result: any) => {
                this.onEventGridPageCounterChanged(result);
            });

        // In Toolbar der Detailansicht wurde auf "Close" gedrückt
        this.toolbarService.eventCloseComponent.pipe(takeUntil(this._componentDestroyed$)).subscribe((result: any) => {
            this.onEventToolbarClose(result);
        });

        // In der Rechnungsliste wurde auf Filter gedrückt
        this.toolbarService.eventFilterInvoices.pipe(takeUntil(this._componentDestroyed$)).subscribe((result: any) => {
            this.onEventFilterInvoices(result);
        });

        // Der Regionsfilter wurde geändert
        this.globalRegionsfilterService.eventRegionsfilterChanged
            .pipe(takeUntil(this._componentDestroyed$))
            .subscribe((result: any) => {
                this.onEventRegionsfilterChanged(result);
            });

        // Nach dem initialisieren den Rechnungsfilter updaten
        this.initService.allInitialized.pipe(takeUntil(this._componentDestroyed$)).subscribe((result: boolean) => {
            if (result) {
                this.updateFilterDate();
            }
        });
        // Doppelt wegen F5 Problemen
        this.updateFilterDate();
    }

    // In der E-Liste wird eine Einrichtung ausgewählt
    onSelectionChanged(id: number) {
        // Bereits vorhandene Daten (einer anderen Einrichtung) entfernen
        this.resetData();
        // ID der aktuellen Einrichtung merken
        this.institutionId = id;
        // Daten laden, falls eine existente Einrichtung ausgewählt wurde
        if (this.institutionId > 0) {
            this.loadDataList();
        }
    }

    // In der Rechnungsliste wird eine Rechnung angeklickt
    onEventGridSelectionChanged(result: any) {
        const event: CWEvent = result;
        if (event.sender === 'institutionsInvoices') {
            // Prüfen, ob Daten vorhanden sind und dann zuweisen
            const selectedRow = result.data?.selectedRow;
            this.editMode = true;
            this.dataInvoicesTotal = selectedRow.invoice_total;
            this.loadDataDetails(selectedRow.order_erp_number, selectedRow.invoice_erp_number);
        }
    }

    // Es wurde auf "Mehr laden..." geklickt
    onEventGridPageCounterChanged(result: any) {
        const event: CWEvent = result;
        if (event.target == 'institutionsInvoices') {
            this.gridPage = event.data['gridPageCounter'];
            this.loadDataList();
        }
    }

    // In Toolbar der Detailansicht wurde auf "Close" gedrückt
    onEventToolbarClose(result: any) {
        const event: CWEvent = result;
        if (event.target == 'institutions-invoices') {
            this.clickCancel();
        }
    }

    /**
     * Auf Klick auf "Filter Invoices..." reagieren
     * @param event
     */
    onEventFilterInvoices(event: CWEvent): void {
        if (event.target == 'institutions-invoices') {
            this.openFilterDialog();
        }
    }

    /**
     * Filter öffnen
     */
    openFilterDialog() {
        // Dialog konfigurieren und öffnen
        const dialogRef = this.filterDialog.open(PopupFormularComponent, {
            width: '450px',
            data: {
                title: this.gridFilterFields.popupTitle ? this.gridFilterFields.popupTitle : 'Filter',
                message: '',
                data: this.institutionsInvoicesFilterForm.inputFields,
            },
        });
        // Auf das Schließen des Dialogs reagieren
        dialogRef.afterClosed().subscribe((result: any) => {
            this.onFilterDialogAnswer(this.gridFilterFields, result);
        });
    }

    /**
     * ???
     * @param selectedFilterItem
     * @param popupResult
     * @todo    cleanup - see sample overview or contact analysis
     */
    onFilterDialogAnswer(selectedFilterItem: FilterData, popupResult: any) {
        // Falls Antwort nicht auswertbar ist oder "Cancel" betätigt wurde, wird nichts weiter gemacht
        if (Object.prototype.hasOwnProperty.call(popupResult, 'answer')) {
            if (popupResult.answer == 'cancel' || popupResult.answer == 'no') {
                return;
            }
        } else {
            return;
        }

        // Formular-Daten (des Popups) an ausgewählten Filter hängen
        if (Object.prototype.hasOwnProperty.call(popupResult, 'formular')) {
            /*
             * Objekt duplizieren, damit die Formularkonfiguration nicht
             * überschrieben wird und das Popup erneut geöffnet werden kann
             */
            const filterItemCopy = {...selectedFilterItem};
            // Formular Daten aus Ergebnis ziehen
            filterItemCopy.formular = popupResult.formular;
            this.invoiceFilter = filterItemCopy;
        } else if (Object.prototype.hasOwnProperty.call(selectedFilterItem, 'formular')) {
            /*
             * Objekt duplizieren, damit die Formularkonfiguration nicht
             * überschrieben wird und das Popup erneut geöffnet werden kann
             */
            const filterItemCopy = {...selectedFilterItem};
            // Formular mit Ergebnis überschreiben
            filterItemCopy.formular = popupResult;
            this.invoiceFilter = filterItemCopy;
            // Attribute, die nicht für den Filter notwendig sind, entfernen
            delete this.invoiceFilter.formular.answer;
            const filterKeys = Object.keys(this.invoiceFilter.formular);
            filterKeys.forEach((inputField: any) => {
                // "_input" in Attributnamen finden
                const hasInputName: boolean = inputField.includes('_input');
                // "_input" von Attributnamen entfernen
                const cleanAttributeName: string = inputField.replace('_input', '');
                /*
                 * Wenn der gefilterte Name bereits existiert, dann das Zusatzfeld aus dem Ergebnis löschen
                 * Wird hauptsächlich bei Jahres- und Monatseingabfeldern auftreten
                 */
                if (hasInputName && filterKeys.some((key: string) => key === cleanAttributeName)) {
                    delete this.invoiceFilter.formular[inputField];
                }
            });
        } else {
            // Gewählten Filter übergeben
            this.invoiceFilter = selectedFilterItem;
        }

        // Formular neu initialisieren mit zwischengespeicherten Werten
        this.institutionsInvoicesFilterForm = new InstitutionsInvoicesFilterForm(this.invoiceFilter.formular);

        // Attribute, die nicht ans Backend übergeben werden sollen, entfernen
        Object.keys(this.invoiceFilter.formular).forEach((element: string) => {
            // "_value_label" in Attributnamen finden
            const hasInputName: boolean = element.includes('_value_label');
            if (hasInputName) {
                delete this.invoiceFilter.formular[element];
            }
        });

        // Daten zurücksetzen
        this.resetData();
        // Filter aktivieren
        this.loadDataList();
    }

    // Der Regionsfilter wurde geändert
    onEventRegionsfilterChanged(result: any) {
        const event: CWEvent = result;
        this.regionsFilter = {
            division: event.data['division'],
            region: event.data['region'],
        };
        if (event.target == 'institutions') {
            this.resetData();
            if (this.institutionId > 0) {
                this.loadDataList();
            }
        }
    }

    // Rechnungs-Daten der Einrichtung laden für Listenansicht
    loadDataList(): void {
        // Funktion "Mehr laden..." wird (wieder) deaktiviert
        this.loadMoreEnabled = false;
        const serviceRequest$ = this.institutionsInvoicesService.loadDataList(
            this.institutionId,
            this.gridPage,
            this.regionsFilter,
            this.invoiceFilter,
        );
        serviceRequest$.subscribe((result: CWResult) => {
            // Falls Daten geladen wurden
            if (result['data']['invoices'].length > 0) {
                /**
                 * Prüfe, ob die Daten des eintreffenden Requests auch
                 * zur aktuell ausgewählten Einrichtung passen. Durch
                 * asynchrone Abfragen kann es nämlich passieren, dass
                 * zwischenzeitlich bereits die Einrichtung gewechselt wurde
                 * und die Antwort eines Requests verspätet eintrifft und
                 * dadurch die korrekten Daten wieder überschreibt.
                 */
                if (this.institutionsService.selectedInstitution && result['data']) {
                    if (this.institutionsService.selectedInstitution.id != result['data']['id']) {
                        return;
                    }
                }
                // Falls sich die vorhandenen Grid-Daten von den neu geladenen Daten unterscheiden...
                if (JSON.stringify(this.dataInvoicesList) != JSON.stringify(result['data']['invoices'])) {
                    // Vorhandene Grid-Daten mit neu geladenen Daten erweitern
                    this.dataInvoicesList = this.dataInvoicesList.concat(result['data']['invoices']);
                }
                // Funktion "Mehr laden..." wird wieder aktiviert
                this.loadMoreEnabled = true;
            } else {
                // Funktion "Mehr laden..." wird ausgeblendet, da es keine weiteren Daten mehr gibt
                this.loadMoreVisible = false;
            }
            // Daten für Selectbox "Produktgruppe" laden
            this.loadProductGroups();
            // Nachdem Daten geladen wurden befindet sich das Modul immer in der normalen Ansicht
            this.editMode = false;
        });
    }

    // Rechnungs-Details (alle Positionen) einer Rechnung laden
    loadDataDetails(orderErpNumber: string, invoiceErpNumber: string): void {
        // Flag setzen
        this.detailsLoading = true;
        // Daten über Service anfragen
        const serviceRequest$ = this.institutionsInvoicesService.loadDataDetails(
            orderErpNumber,
            invoiceErpNumber,
            this.regionsFilter,
        );
        serviceRequest$.subscribe((result: CWResult) => {
            // Falls Daten geladen wurden
            if (result['data'].length > 0) {
                this.dataInvoicesPositions = result['data'];
            }
            // Flag zurücksetzen
            this.detailsLoading = false;
        });
    }

    // Hole die Daten aus dem Storage und speichere sie in die Select-Data-Optionen für den Multi-Input
    loadProductGroups() {
        this.storageService.getItem('listentries|productGroups').then((data: any) => {
            const productsArrays: any[] = Object.values(data);

            // Array für Ergebnis-Array
            let resultArray: any[] = [];

            // Alle Produktgruppen durchgehen
            productsArrays.forEach((product) => {
                // Produkte mit gleichem Id ausfiltern
                resultArray = resultArray.filter((element) => element.product_id !== product.product_id);
                resultArray.push(product);
            });

            this.productGroupsSelect = resultArray;
            this.institutionsInvoicesFilterForm.inputFields[0]['field_select_data'] = this.productGroupsSelect;
        });
    }

    // Klick auf "Abbrechen"
    clickCancel(): void {
        // EditMode verlassen
        this.editMode = false;
    }

    // Daten zurücksetzen (z.B. wenn Einrichtung gewechselt wird)
    resetData(): void {
        this.editMode = false;
        this.loadMoreEnabled = false;
        this.loadMoreVisible = true;
        this.gridPage = 1;
        this.dataInvoicesList = [];
        this.dataInvoicesTotal = 0;
    }

    // Filter-Zeitraum aktualisieren
    updateFilterDate() {
        this.storageService.getItem('config|invoicesFilterLoadedMonths').then((monthsToLoad) => {
            // Aktuellen Filter kopieren und Start- und Enddatum anpassen
            const updatedInvoiceFilter = {
                ...this.invoiceFilter,
                formular: {
                    ...this.invoiceFilter.formular,
                    startDate: moment().subtract(monthsToLoad, 'months').format(MOMENT_DB_FORMAT),
                    endDate: moment().format(MOMENT_DB_FORMAT),
                },
            };
            this.invoiceFilter = updatedInvoiceFilter;
        });
    }
}
