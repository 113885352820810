// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/**
 * Allgemein
 * =========
 *
 * @author  Massimo Feth <m.feth@pharmakon.software>
 */
:host .cw-submodule > div {
  width: 350px;
}

:host .cw-supplier + .cw-supplied {
  margin-top: 20px;
}`, "",{"version":3,"sources":["webpack://./src/app/shared/entity-affiliation/entity-affiliation.component.scss"],"names":[],"mappings":"AAAA;;;;;EAAA;AAOA;EACI,YAAA;AAAJ;;AAGA;EACI,gBAAA;AAAJ","sourcesContent":["/**\n * Allgemein\n * =========\n *\n * @author  Massimo Feth <m.feth@pharmakon.software>\n */\n\n:host .cw-submodule > div {\n    width: 350px;\n}\n\n:host .cw-supplier + .cw-supplied {\n    margin-top: 20px;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
