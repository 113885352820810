// Angular-Module
import {Injectable} from '@angular/core';
// ReactiveX for JavaScript
import {Observable, forkJoin, map} from 'rxjs';
// Globale Services
import {AppCoreService} from '@global/services/app-core.service';
import {BackendService} from '@global/services/backend.service';
// Interfaces für Structured Objects einbinden
import {LooseObject} from '@shared/loose-object';
import { EntityAffiliation } from '@shared/entity-affiliation';

@Injectable({providedIn: 'root'})
export class EntityAffiliationService {
    /**
     * Konstruktor (inkl. dependency injection)
     * @param appCore
     * @param backendService
     */
    constructor(private appCore: AppCoreService, private backendService: BackendService) {}

    /**
     * @param id
     * @brief   Stammdaten einer Einrichtung laden
     * @returns  Observable<any>
     */
    loadData(id: number): Observable<any> {
        const parentsUrl = `/crud/EntityAffiliation/index?query={"filter":{"child_key":["${id}"]}}`;
        const childrenUrl = `/crud/EntityAffiliation/index?query={"filter":{"parent_key":["${id}"]}}`;

        const parentsRequest$: Observable<unknown> = this.backendService.getRequest(parentsUrl);
        const childrenRequest$: Observable<unknown> = this.backendService.getRequest(childrenUrl);

        return forkJoin([parentsRequest$, childrenRequest$]).pipe(
            map(([parent, child]) => ({
                parent,
                child,
            })),
        );
    }

    /**
     * @param formData
     * @param id
     * @brief   Daten einer Verknüpfung speichern
     * @returns  Observable<any>
     */
    saveData(formData: LooseObject, id: string): Observable<any> {
        // POST-Request über BackendService senden
        const postRequest$: Observable<any> = this.backendService.postRequest(`/crud/EntityAffiliation/edit/${id}`, formData);
        // Observable (an Komponente) zurücklieferen
        return postRequest$;
    }

    crudCreateData(data: EntityAffiliation): Observable<any> {
        // POST-Request über BackendService senden
        const postRequest$: Observable<any> = this.backendService.postRequest('/crud/EntityAffiliation/create/', data);
        // Observable (an Komponente) zurücklieferen
        return postRequest$;
    }

    /**
     * @param formData
     * @brief   Daten einer Verknüpfung löschen
     * @returns  Observable<any>
     */
    deleteData(formData: LooseObject): Observable<any> {
        // POST-Request über BackendService senden
        const postRequest$: Observable<any> = this.backendService.postRequest('EntityAffiliation/delete/', formData);
        // Observable (an Komponente) zurücklieferen
        return postRequest$;
    }

    /**
     * @param formData
     * @brief   Daten einer Verknüpfung löschen
     * @returns  Observable<any>
     */
    crudDeleteData(id: string): Observable<any> {
        // POST-Request über BackendService senden
        const postRequest$: Observable<any> = this.backendService.deleteRequest(`/crud/EntityAffiliation/delete/${id}`, {id});
        // Observable (an Komponente) zurücklieferen
        return postRequest$;
    }
}
