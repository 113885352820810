/**
 * @brief   Shared GridModule zur Freigabe der GridComponent.
 * @details Um die GridComponent in Feature Modulen verwenden zu können,
 *          wird dieses Shared Module eingebunden.
 *          Die Einbindung erfolgt im Feature Module (z.B. people.module.ts)
 *          und nicht in den einzelnen Komponenten des Feature Moduls.
 *
 *          // Shared Modules einbinden
 *          import {GridModule} from '../../shared/grid/grid.module';
 * @see     https://github.com/angular/material2/issues/5927
 *          https://alligator.io/angular/reusable-components-ngtemplateoutlet/
 * @author  Massimo Feth <m.feth@pharmakon.software>
 */

// Angular-Module
import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {FormsModule} from '@angular/forms';
// CDK & Angular Material
import {DragDropModule} from '@angular/cdk/drag-drop';
import {CdkTableModule} from '@angular/cdk/table';
import {MatButtonModule} from '@angular/material/button';
import {MatCardModule} from '@angular/material/card';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatExpansionModule} from '@angular/material/expansion';
import {MatIconModule} from '@angular/material/icon';
import {MatMenuModule} from '@angular/material/menu';
import {MatRadioModule} from '@angular/material/radio';
import {MatSnackBarModule} from '@angular/material/snack-bar';
import {MatSortModule} from '@angular/material/sort';
import {MatTableModule} from '@angular/material/table';
import {MatTooltipModule} from '@angular/material/tooltip';
// Eigene Pipes
import {sanitizeHtmlPipe} from './../../sanitize-html.pipe';
// Service dieses Shared-Modules
import {GridService} from './grid.service';
// Komponenten
import {GridCellEditListentryComponent} from './grid-cell-edit-listentry/grid-cell-edit-listentry.component';
import {GridColumnsPanelComponent} from './grid-columns-panel/grid-columns-panel.component';
import {GridColumnsSavePopupComponent} from './grid-columns-panel/grid-columns-save-popup/grid-columns-save-popup.component';
import {GridFilterComponent} from './grid-filter/grid-filter.component';
import {GridLayoutComponent} from './grid-layout/grid-layout.component';
import {GridLoadMoreComponent} from './grid-load-more/grid-load-more.component';
import {GridSelectionButtonComponent} from './grid-selection/grid-selection-button/grid-selection-button.component';
import {GridSelectionFilterbuttonComponent} from './grid-selection/grid-selection-panel/grid-selection-filterbutton/grid-selection-filterbutton.component';
import {GridSelectionOperatorbuttonComponent} from './grid-selection/grid-selection-panel/grid-selection-operatorbutton/grid-selection-operatorbutton.component';
import {GridSelectionPanelComponent} from './grid-selection/grid-selection-panel/grid-selection-panel.component';
import {GridSelectionPopupComponent} from './grid-selection/grid-selection-popup/grid-selection-popup.component';
import {GridSelectionSavePopupComponent} from './grid-selection/grid-selection-save-popup/grid-selection-save-popup.component';
import {GridSortComponent} from './grid-sort/grid-sort.component';
import {GridSourceComponent} from './grid-source/grid-source.component';
import {GridActionsComponent} from './grid/grid-actions/grid-actions.component';
import {GridClearingChangedFieldComponent} from './grid/grid-clearing-changed-field/grid-clearing-changed-field.component';
import {GridContactPersonComponent} from './grid/grid-contact-person/grid-contact-person.component';
import {GridContactInfoComponent} from './grid/grid-contact-info/grid-contact-info.component';
import {GridContactTopicsOfConversationComponent} from './grid/grid-contact-topics-of-conversation/grid-contact-topics-of-conversation.component';
import {GridContactMethodComponent} from './grid/grid-contact-method/grid-contact-method.component';
import {GridContactTypeComponent} from './grid/grid-contact-type/grid-contact-type.component';
import {GridDailyreportApprovalComponent} from './grid/grid-dailyreport-approval/grid-dailyreport-approval.component';
import {GridDailyreportFileComponent} from './grid/grid-dailyreport-file/grid-dailyreport-file.component';
import {GridEditDeleteComponent} from './grid/grid-edit-delete/grid-edit-delete.component';
import {GridHistoryComponent} from './grid/grid-history/grid-history.component';
import {GridIconContactComponent} from './grid/grid-icon-contact/grid-icon-contact.component';
import {GridIconFavoriteComponent} from './grid/grid-icon-favorite/grid-icon-favorite.component';
import {GridIconComponent} from './grid/grid-icon/grid-icon.component';
import {GridInstitutionNameComponent} from './grid/grid-institution-name/grid-institution-name.component';
import {GridPersonSpecializationFunctionComponent} from './grid/grid-person-specialization-function/grid-person-specialization-function.component';
import {GridPersonSpecializationComponent} from './grid/grid-person-specialization/grid-person-specialization.component';
import {GridPersonTitleNameComponent} from './grid/grid-person-title-name/grid-person-title-name.component';
import {GridSalesAnalysisNameComponent} from './grid/grid-sales-analysis-name/grid-sales-analysis-name.component';
import {GridSupplementaryNumbersComponent} from './grid/grid-supplementary-numbers/grid-supplementary-numbers.component';
import {GridUpDownComponent} from './grid/grid-up-down/grid-up-down.component';
import {GridComponent} from './grid/grid.component';
import {FontAwesomeModule} from '@fortawesome/angular-fontawesome';
// Shared Modules einbinden
import {EntityIconsModule} from '../../shared/entity-icons/entity-icons.module';
import {InputModule} from '../../shared/input/input.module';
import {OverlayModule} from '../../shared/overlay/overlay.module';
import {SnackbarsModule} from '../../shared/snackbars/snackbars.module';
import {PipesModule} from '../pipes/pipes.module';
// import { GridSelectionContactCountPopupComponent } from './grid-selection/grid-selection-popup/grid-selection-contact-count-popup/grid-selection-contact-count-popup.component';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        DragDropModule,
        CdkTableModule,
        MatButtonModule,
        MatCardModule,
        MatCheckboxModule,
        MatExpansionModule,
        MatRadioModule,
        MatTableModule,
        MatMenuModule,
        MatIconModule,
        MatTooltipModule,
        MatSortModule,
        MatSnackBarModule,
        InputModule,
        OverlayModule,
        EntityIconsModule,
        SnackbarsModule,
        PipesModule,
        FontAwesomeModule,
    ],
    declarations: [
        GridComponent,
        GridColumnsPanelComponent,
        GridFilterComponent,
        GridLayoutComponent,
        GridLoadMoreComponent,
        GridSortComponent,
        GridSourceComponent,
        sanitizeHtmlPipe,
        GridSelectionButtonComponent,
        GridSelectionPanelComponent,
        GridSelectionPopupComponent,
        GridSelectionFilterbuttonComponent,
        GridSelectionOperatorbuttonComponent,
        GridSelectionSavePopupComponent,
        GridIconComponent,
        GridPersonTitleNameComponent,
        GridContactTypeComponent,
        GridContactMethodComponent,
        GridContactInfoComponent,
        GridContactTopicsOfConversationComponent,
        GridUpDownComponent,
        GridActionsComponent,
        GridEditDeleteComponent,
        GridColumnsSavePopupComponent,
        GridInstitutionNameComponent,
        GridSalesAnalysisNameComponent,
        GridIconFavoriteComponent,
        GridIconContactComponent,
        GridHistoryComponent,
        GridClearingChangedFieldComponent,
        GridPersonSpecializationComponent,
        GridPersonSpecializationFunctionComponent,
        GridCellEditListentryComponent,
        GridSupplementaryNumbersComponent,
        GridDailyreportFileComponent,
        GridDailyreportApprovalComponent,
        GridContactPersonComponent,
        // GridSelectionContactCountPopupComponent,
    ],
    // Komponenten freigeben
    exports: [
        GridComponent,
        GridColumnsPanelComponent,
        GridFilterComponent,
        GridLayoutComponent,
        GridLoadMoreComponent,
        GridSelectionFilterbuttonComponent,
        GridSelectionPanelComponent,
        GridSortComponent,
        GridSourceComponent,
        sanitizeHtmlPipe,
        MatTooltipModule,
    ],
    providers: [GridService],
})
export class GridModule {}
